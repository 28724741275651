<template>
  <div class="container">
    <div class="personal__title title">
      Мои заказы
    </div>
    <div class="personal-orders" v-if="orders.length > 0">
      <div class="personal-order" v-for="order in orders" :key="order.id">
        <router-link
          :to="{ name: 'current-order', params: { orderId: order.id } }"
          class="order-card"
          :class="getClass(order.status)"
        >
          <span class="order-card__head">
            <span class="order-card__info">
              <span class="order-card__info-number">
                Заказ № {{ order.id }}
              </span>
              <span class="order-card__info-count">
                {{ infoCount(order.productCount) }}
              </span>
            </span>
            <span class="order-card__date">
              {{ order.data }}
            </span>
          </span>
          <span class="order-card__body">
            <span class="order-card__status">
              {{ order.status }}
            </span>
            <span class="order-card__link">
              <svg class="icon icon-arrow ">
                <use xlink:href="#arrow"></use>
              </svg>
            </span>
          </span>
        </router-link>
      </div>
    </div>

    <div class="welcome container" v-else>
      <img src="/img/paladi.png" class="welcome__logo" />
      <div class="welcome__subtitle">
        Вы ещё не совершали заказ
      </div>
      <button class="btn welcome__btn" @click="$router.push('/catalog/menu')">
        В каталог
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";

export default {
  beforeRouteEnter(to, from, next) {
    if (to.query.category === "new-orders")
      store.dispatch("PERSONAL_FETCH_ORDERS").then(() => {
        next();
      });
    else
      store.dispatch("PERSONAL_FETCH_ORDERS_HISTORY").then(() => {
        next();
      });
  },

  computed: {
    orders() {
      return this.$store.state.personal.orders;
    },
  },

  methods: {
    infoCount(productCount) {
      const cases = [2, 0, 1, 1, 1, 2];
      const words = ["товар", "товара", "товаров"];

      return (
        productCount +
        " " +
        words[
          productCount % 100 > 4 && productCount % 100 < 20
            ? 2
            : cases[productCount % 10 < 5 ? productCount % 10 : 5]
          ]
      );
    },

    getClass(status) {
      const statusClose = ['Выполнен', 'Отменен', 'Возврат'];

      if (!statusClose.includes(status))
        return "order-card--active";
    },
  },
};
</script>
